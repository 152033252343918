import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

$(function() {
	$('ul.js-img-animation').each(function() {
		let ul = this;
		let index = 0;
		let items = $('>li', ul);
		if (items.length) {
			$(items[index]).addClass('is-show');
		}

		if (items.length > 1) {
			let fadeTimeout = function() {
				let next = index + 1;
				if (next >= items.length) {
					next = 0;
				}
				$(items[index]).addClass('is-show is-prev');
				$(items[next]).addClass('is-next');
				setTimeout(function() {
					$(items[index]).removeClass('is-show is-prev');
					$(items[next]).addClass('is-show').removeClass('is-next');
					index = next;
				}, 1500);
			};
			setInterval(fadeTimeout, 3000);
		}
	});
});

$(function() {
	$('.js-slider1').each(function() {
		new Swiper(this, {
			loop: false,
			slidesPerView: 'auto',
			//centeredSlides: true,
			spaceBetween: 0,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			breakpoints: {
				768: {
					loop: true,
				},
			},
		});
	});

	$('.js-slider2').each(function() {
		new Swiper(this, {
			loop: false,
			slidesPerView: 'auto',
			//centeredSlides: true,
			spaceBetween: 0,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			breakpoints: {
				768: {
					loop: true,
				},
			},
		});
	});
});
