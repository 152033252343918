import $ from 'jquery';

$(document).on('click', 'a[href*="#"]', function(e) {
	if (window.common.isBodyFixed()) {
		$('#header-btn').removeClass('is-active');
		$('#header-gnav').removeClass('is-active');
		$('header').removeClass('is-active');
		window.common.bodyFixed(false);
	}
	let hash = this.hash;
	let target = $(hash === '#' || hash === '' ? 'html' : hash);
	if (target.length === 0) {
		target = $('[data-anchor="' + hash.slice(1) + '"]');
	}
	let locationPath = window.location.pathname.replace(/\//g, '');
	let thisPath = this.pathname.replace(/\//g, '');
	if (target.length && locationPath === thisPath) {
		e.preventDefault();
		let headerHeight = $('header').innerHeight();
		let targetPosition = target.offset().top;
		let scrollStop = function() {
			$('body, html').stop(true);
		};
		$('body, html').animate({ scrollTop: targetPosition - headerHeight }, 800, 'swing');
		window.addEventListener('DOMMouseScroll', scrollStop, false);
		window.onmousewheel = document.onmousewheel = scrollStop;
	}
});


$(window).on('load', function() {
	//console.log('load');

	/* URL要素に移動
	/*---------------------------------------------------------*/
	(function() {
		let localLink = window.location + '';
		if (localLink.indexOf('#') !== -1) {
			let hash = localLink.slice(localLink.indexOf('#'));
			let dataAnchor = $('[data-anchor="' + hash.slice(1) + '"]');
			let scrollTop = 0;
			if ($(hash).length) {
				scrollTop = $(hash).offset().top;
			} else if (dataAnchor.length) {
				scrollTop = dataAnchor.offset().top;
			}
			let headerHeight = $('header').innerHeight();
			$('html,body').animate({ scrollTop: scrollTop - headerHeight }, 800, 'swing');
		}
	})();

});
