import $ from 'jquery';
//import { window } from '../../../root/wp-includes/js/codemirror/csslint';

$(function() {
	let header = $('header');
	let btn = $('#header-btn');
	let gnav = $('#header-gnav');
	if (header.length && btn.length && gnav.length) {
		btn.on('click', function() {
			$(this).toggleClass('is-active');
			header.toggleClass('is-active');
			gnav.toggleClass('is-active');
			if (btn.hasClass('is-active')) {
				window.common.bodyFixed(true);
			} else {
				window.common.bodyFixed(false);
			}
		});
	}
});

$(window).on('load scroll', function() {
	let mvTop = $('#top-mv');
	let headerStyle1 = $('header.c-header--style1');
	if (mvTop.length && headerStyle1.length) {
		let scrollTop = $(window).scrollTop();
		if (scrollTop >= mvTop.innerHeight()) {
			headerStyle1.addClass('is-scroll-fixed');
		} else {
			headerStyle1.removeClass('is-scroll-fixed');
		}
	}
});
