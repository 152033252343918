import $ from 'jquery';

const common = {

	getOffsetTop: function(domEl) {
		let offsetTop = domEl.offsetTop;
		if (domEl.offsetParent) {
			offsetTop += window.settings.getOffsetTop(domEl.offsetParent);
		}
		return offsetTop;
	},

	bodyFixedTop: 0,
	bodyFixedLeft: 0,
	bodyFixed: function(fixed = true) {
		if (fixed) {
			common.bodyFixedTop = $(window).scrollTop();
			common.bodyFixedLeft = $(window).scrollLeft();
			let fixedLeft = 0;
			let overflowX = 'auto';
			if (common.bodyFixedLeft > 0) {
				fixedLeft = common.bodyFixedLeft;
				overflowX = 'scroll';
			}
			document.body.style.position = 'fixed';
			document.body.style.top = (common.bodyFixedTop * -1) + 'px';
			document.body.style.left = fixedLeft * -1 + 'px';
			document.body.style.overflowX = overflowX;
			document.body.style.overflowY = 'hidden';
			document.body.classList.add('is-fixed');
		} else {
			document.body.removeAttribute('style');
			document.body.classList.remove('is-fixed');
			window.scrollTo(common.bodyFixedLeft, common.bodyFixedTop);
		}
	},

	isBodyFixed: function() {
		return document.body.classList.contains("is-fixed");
	},
};

window.common = Object.create(common);

$(function() {
	let resizeTimeout;
	$(window).on('resize', function() {
		clearTimeout(resizeTimeout);
		document.body.classList.add('is-resizing');
		resizeTimeout = setTimeout(function() {
			document.body.classList.remove('is-resizing');
		}, 200);
	});
});
